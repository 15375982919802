import { BoxRow } from 'components/common'
import React from 'react'
import { Reviewers, ReviewersProps } from './components/Reviewers/Reviewers'
import { Task, TaskProps } from './components/Task/Task'
import { Owner, OwnerProps } from './components/Owner/Owner'

export interface ReviewItemProps {
  taskProps: TaskProps
  ownerProps: OwnerProps
  reviewersProps: ReviewersProps
}

export const ReviewItem: React.FC<ReviewItemProps> = ({
  taskProps,
  ownerProps,
  reviewersProps
}) => {
  return (
    <BoxRow sx={{ justifyContent: 'space-between', gap: '10px' }}>
      <Task {...taskProps} />
      <BoxRow>
        <Owner {...ownerProps} />
        <Reviewers {...reviewersProps} />
      </BoxRow>
    </BoxRow>
  )
}
