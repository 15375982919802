import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { InputField, InputFieldProps } from './InputField'

export interface TextFieldProps extends InputFieldProps {
  debounceTime?: number
  rows?: number
  multiline?: boolean
}

export const TextField: React.FC<TextFieldProps> = ({
  debounceTime = 0,
  value = '',
  onChange,
  rows,
  multiline = false,
  ...rest
}) => {
  const [internalValue, setInternalValue] = React.useState<string>(value)
  const [pendingSubmission, setPendingSubmission] =
    React.useState<boolean>(false)

  const clearMultiline = (inputValue: string) => {
    // Replace consecutive new lines with a single space.
    return inputValue.replace(/(\r\n|\n|\r)+/gm, ' ')
  }

  const isMultiline = (inputValue: string) => {
    return inputValue.includes('\n')
  }

  React.useEffect(() => {
    if (!pendingSubmission) {
      if (isMultiline(value) && !multiline) {
        // If it's a multiline, clear it and update the external value.
        handleInputChange(clearMultiline(value))
      } else {
        // Update the internal value.
        setInternalValue(value)
      }
    }
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
        setPendingSubmission(false)
      }
    },
    debounceTime
  )

  React.useEffect(() => {
    if (isMultiline(value) && !multiline) {
      handleInputChange(clearMultiline(value))
    }
  }, [multiline])

  const handleInputChange = (inputValue: string) => {
    // If it's not multiline, prevent new lines. This is used to have multiple rows but single line.
    if (!multiline) {
      inputValue = inputValue.replace(/(\r\n|\n|\r)/gm, '')
    }
    setInternalValue(inputValue)
    setPendingSubmission(true)
    handleInputChangeDebounced(inputValue.trim())
  }

  return (
    <InputField
      value={internalValue}
      onChange={handleInputChange}
      rows={rows}
      multiline={!!rows || multiline}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !multiline) {
          event.preventDefault()
        }
      }}
      {...rest}
    />
  )
}
