import { MeasurementState } from 'models/Measurement'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  addConversion,
  getConversions,
  getLiquidMeasurements,
  removeConversion,
  updateConversion
} from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import { ConversionItemProps } from './components/ConversionItem'
import { MeasurementOption, Measurements } from './Measurements'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  getSimpleIngredient,
  setSelectedSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'

export interface MeasurementsContainerProps {
  ingredientId: string
  disabled?: boolean
}

export const MeasurementsContainer: React.FC<MeasurementsContainerProps> = ({
  ingredientId,
  disabled
}) => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)

  const liquidMeasurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.liquidMeasurements
  )

  const ingredientConversions = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.conversions
  )

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  // TODO: This is incorrect and should not be here. The conversion change should trigger a function that is outside this container.
  const updateSelectedIngredient = () => {
    void dispatch(
      getSimpleIngredient({
        companyId: currentCompany.id,
        simpleIngredientId: ingredientId
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(setSelectedSimpleIngredient(res))
      })
  }

  React.useEffect(() => {
    void dispatch(
      getConversions({
        companyId: currentCompany.id,
        simpleIngredientId: ingredientId
      })
    )
    if (liquidMeasurements === undefined) {
      void dispatch(
        getLiquidMeasurements({
          companyId: currentCompany.id
        })
      )
    }
  }, [ingredientId])

  const handleChange = (id: string, fromAmount: number, toAmount: number) => {
    void dispatch(
      updateConversion({
        companyId: currentCompany.id,
        simpleIngredientId: ingredientId,
        conversionId: id,
        amount: toAmount,
        amountInMeasurement: fromAmount
      })
    ).then(() => {
      // TODO: Should not be here. This should be triggered somewhere else that is listening to the conversion changes.
      updateSelectedIngredient()
    })
  }

  const handleRemove = (id: string) => {
    showConfirmationModal({
      title: 'Remove Measurement',
      message:
        'Are you sure you want to remove this measurement? All related or derived measurements used for this ingredient will revert to grams.',
      danger: true,
      yesText: 'Remove',
      noText: 'Cancel',
      onYesClicked: () => {
        void dispatch(
          removeConversion({
            companyId: currentCompany.id,
            simpleIngredientId: ingredientId,
            conversionIds: [id]
          })
        ).then(() => {
          // TODO: Should not be here. This should be triggered somewhere else that is listening to the conversion changes.
          updateSelectedIngredient()
        })
      }
    })
  }

  const handleMeasurementSelect = (measurement: MeasurementOption) => {
    void dispatch(
      addConversion({
        companyId: currentCompany.id,
        simpleIngredientId: ingredientId,
        measurementId: measurement.id,
        amountInMeasurement: 1,
        amount: 1
      })
    ).then(() => {
      // TODO: Should not be here. This should be triggered somewhere else that is listening to the conversion changes.
      updateSelectedIngredient()
    })
  }

  const conversions: ConversionItemProps[] = React.useMemo(() => {
    return (
      ingredientConversions?.map((ic) => {
        return {
          id: ic.id,
          fromMeasurement: {
            amount: ic.amountInMeasurement,
            unit: ic.measurement.unit,
            onChange: (amount) =>
              handleChange(ic.id, amount, ic.equivalentInGrams)
          },
          toMeasurement: {
            amount: ic.equivalentInGrams,
            unit: 'g',
            onChange: (amount) =>
              handleChange(ic.id, ic.amountInMeasurement, amount)
          },
          disabled: disabled,
          onRemove: handleRemove
        }
      }) || []
    )
  }, [ingredientConversions])

  const measurementOptions: MeasurementOption[] = React.useMemo(() => {
    return (
      liquidMeasurements?.map((lm) => {
        return {
          id: lm.id,
          name: lm.name,
          unit: lm.unit
        }
      }) || []
    )
  }, [liquidMeasurements])

  return (
    <Measurements
      conversions={conversions}
      measurementOptions={measurementOptions}
      onMeasurementSelect={handleMeasurementSelect}
      disabled={disabled}
    />
  )
}
