import { FilterSearchResponse } from 'components/EntrTable/Filter/FiltersHook'
import { getOptionsFromFilterApiResponse } from 'components/EntrTable/Filter/helpers'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { MeasurementType } from 'models/Measurement'
import React from 'react'
import { SupplierApi } from 'services/apis/supplier/SupplierApi'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getMeasurements } from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import {
  addSimpleIngredientAllergen,
  addSimpleIngredientTag,
  addSimpleIngredientToSupplier,
  createSimpleIngredientCost,
  deleteSimpleIngredientAllergen,
  deleteSimpleIngredientCost,
  deleteSimpleIngredientFromSupplier,
  deleteSimpleIngredientTag,
  getSimpleIngredientAllergens,
  updateSimpleIngredient,
  updateSimpleIngredientCost
} from 'state/simple_ingredients/SimpleIngredientsSlice'

import { AutocompleteChangeReason } from 'components/Autocomplete/Autocomplete'
import { useAutocomplete } from 'components/Autocomplete/AutocompleteHook'
import { Option } from 'components/common'
import { TagsApi } from 'services/apis/tags/TagsApi'
import { getAllergens } from 'state/allergens/AllergensSlice'
import {
  Cost,
  UpdateCostArgs
} from './components/IngredientCost/IngredientCostRow'
import { Field, Ingredient, IngredientForm } from './IngredientForm'

export interface IngredientFormContainerProps {
  disabled?: boolean
}

export const IngredientFormContainer: React.FC<
  IngredientFormContainerProps
> = ({ disabled = true }) => {
  const dispatch = useAppDispatch()

  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const { showError } = React.useContext(SnackbarContext)
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )

  const allergenOptions = useAppSelector((state) => state.allergens.allergens)
  const selectedAllergens = useAppSelector(
    (state) => state.simpleIngredients.currentSimpleIngredientAllergens
  ).map((a) => a.allergen.type)
  const loadingSelectedAllergens = useAppSelector(
    (state) => state.simpleIngredients.loadingAllergens
  )

  const getMeasurementIdFromType = (type: MeasurementType) => {
    if (!measurements) {
      return ''
    }
    const measurement = measurements.find((m) => m.type === type)
    return measurement?.id || ''
  }

  React.useEffect(() => {
    if (!measurements?.length) {
      void dispatch(
        getMeasurements({
          companyId: currentCompany.id
        })
      )
    }

    if (!allergenOptions.length) {
      void dispatch(getAllergens())
    }
  }, [currentCompany.id])

  React.useEffect(() => {
    if (selectedIngredient?.id) {
      void dispatch(
        getSimpleIngredientAllergens({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id
        })
      )
    }
  }, [selectedIngredient?.id, currentCompany.id])

  const handleSuppliersSearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return SupplierApi.getSuppliers(currentCompany.id, {
        simpleSupplierFuzzyName: search,
        page: page,
        size: 10
      })
        .then((suppliers) => {
          return {
            options: suppliers,
            selected: selectedIngredient?.suppliers || []
          }
        })
        .then((suppliersFilter) => {
          return getOptionsFromFilterApiResponse(
            suppliersFilter,
            (responseItem) => {
              return {
                label: responseItem.name,
                value: false,
                id: responseItem.id
              }
            },
            selectedIngredient?.suppliers.map((s) => s.id) ?? [],
            Boolean(search)
          )
        })
    },
    [selectedIngredient?.suppliers, currentCompany.id]
  )

  const handleSuppliersChange = React.useCallback(
    (option: Option<boolean>, reason: AutocompleteChangeReason) => {
      if (selectedIngredient?.id) {
        if (reason === AutocompleteChangeReason.ADD) {
          return dispatch(
            addSimpleIngredientToSupplier({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              supplierId: option.id
            })
          ).then(() => {
            return { ...option, value: true }
          })
        } else if (reason === AutocompleteChangeReason.REMOVE) {
          return dispatch(
            deleteSimpleIngredientFromSupplier({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              supplierId: option.id
            })
          ).then(() => {
            return { ...option, value: false }
          })
        }
      }
      return Promise.reject()
    },
    [currentCompany.id, selectedIngredient?.id]
  )

  const suppliers = useAutocomplete({
    onSearchChange: handleSuppliersSearchChange,
    onChange: handleSuppliersChange,
    contextKey: selectedIngredient?.id
  })

  const handleAllergensSearchChange =
    React.useCallback((): Promise<FilterSearchResponse> => {
      return Promise.resolve().then(() => {
        return {
          options: allergenOptions.map((fs) => ({
            label: fs.name,
            value: selectedAllergens.includes(fs.type),
            id: fs.type
          })),
          page: 1,
          pages: 1
        } as FilterSearchResponse
      })
    }, [allergenOptions, selectedAllergens])

  const handleAllergensChange = React.useCallback(
    (option: Option<boolean>, reason: AutocompleteChangeReason) => {
      if (selectedIngredient?.id) {
        if (reason === AutocompleteChangeReason.ADD) {
          return dispatch(
            addSimpleIngredientAllergen({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              allergenType: option.id
            })
          ).then(() => {
            return { ...option, value: true }
          })
        } else if (reason === AutocompleteChangeReason.REMOVE) {
          return dispatch(
            deleteSimpleIngredientAllergen({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              allergenType: option.id
            })
          ).then(() => {
            return { ...option, value: false }
          })
        }
      }
      return Promise.reject()
    },
    [currentCompany.id, selectedIngredient?.id]
  )

  const allergens = useAutocomplete({
    onSearchChange: handleAllergensSearchChange,
    onChange: handleAllergensChange,
    contextKey: `${selectedIngredient?.id || ''}-${String(
      loadingSelectedAllergens
    )}`,
    localSearch: true
  })

  const handleTagsSearchChange = React.useCallback(
    (search?: string, page = 1): Promise<FilterSearchResponse> => {
      return TagsApi.getTags(currentCompany.id, {
        simpleTagFuzzyName: search,
        page: page,
        size: 10
      })
        .then((tags) => {
          return {
            options: tags,
            selected: selectedIngredient?.tags || []
          }
        })
        .then((tagsFilter) => {
          return getOptionsFromFilterApiResponse(
            tagsFilter,
            (responseItem) => {
              return {
                label: responseItem.name,
                value: false,
                id: responseItem.id
              }
            },
            selectedIngredient?.tags.map((s) => s.id) ?? [],
            Boolean(search)
          )
        })
    },
    [selectedIngredient?.tags, currentCompany.id]
  )

  const handleTagsChange = React.useCallback(
    (option: Option<boolean>, reason: AutocompleteChangeReason) => {
      if (selectedIngredient?.id) {
        if (
          [
            AutocompleteChangeReason.ADD,
            AutocompleteChangeReason.CREATE
          ].includes(reason)
        ) {
          return dispatch(
            addSimpleIngredientTag({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              name: option.label as string
            })
          )
            .unwrap()
            .then((o) => {
              return { ...option, id: o.tag.id, value: true }
            })
        } else if (reason === AutocompleteChangeReason.REMOVE) {
          return dispatch(
            deleteSimpleIngredientTag({
              companyId: currentCompany.id,
              simpleIngredientId: selectedIngredient.id,
              tagId: option.id
            })
          ).then(() => {
            return { ...option, value: false }
          })
        }
      }
      return Promise.reject()
    },
    [currentCompany.id, selectedIngredient?.id]
  )

  const tags = useAutocomplete({
    onSearchChange: handleTagsSearchChange,
    onChange: handleTagsChange,
    contextKey: selectedIngredient?.id
  })

  const ingredient: Ingredient = React.useMemo(() => {
    // TODO: Defaulting to empty strings because some values are null and not undefined. Better handling is needed.
    return {
      id: selectedIngredient?.id || '',
      name: selectedIngredient?.name || '',
      ingredientStatement: selectedIngredient?.ingredientStatement || '',
      verified: selectedIngredient?.verified || false,
      friendlyId: selectedIngredient?.friendlyId || '',
      manufacturer: selectedIngredient?.manufacturer || '',
      description: selectedIngredient?.description || '',
      isSugar: selectedIngredient?.isSugar || false,
      assay: selectedIngredient?.assay || '',
      botanicalName: selectedIngredient?.botanicalName || '',
      source: selectedIngredient?.source || '',
      suppliers:
        selectedIngredient?.suppliers.map((s) => {
          return {
            label: s.name,
            value: true,
            id: s.id
          }
        }) || []
    }
  }, [selectedIngredient])

  const handleChange = React.useCallback(
    (field: Field, value: string | number | boolean) => {
      if (selectedIngredient?.id) {
        void dispatch(
          updateSimpleIngredient({
            companyId: currentCompany.id,
            simpleIngredientId: selectedIngredient?.id,
            [field]: value
          })
        ).catch(() => {
          showError("Couldn't update ingredient.")
        })
      }
    },
    [selectedIngredient]
  )

  const handleCreateCost = () => {
    if (selectedIngredient) {
      void dispatch(
        createSimpleIngredientCost({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id,
          measurementId: getMeasurementIdFromType(MeasurementType.KILOGRAM),
          cost: 0
        })
      ).catch(() => {
        showError(`Failed to add cost for ${selectedIngredient.name}.`)
      })
    }
  }

  const handleDeleteCost = (costId: string) => {
    if (selectedIngredient) {
      void dispatch(
        deleteSimpleIngredientCost({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id,
          simpleIngredientCostId: costId
        })
      ).catch(() => {
        showError(`Failed to delete cost for ${selectedIngredient.name}.`)
      })
    }
  }

  const handleUpdateCost = (costId: string, args: UpdateCostArgs) => {
    if (selectedIngredient?.id) {
      void dispatch(
        updateSimpleIngredientCost({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id,
          simpleIngredientCostId: costId,
          measurementId: args.measurementUnit?.id,
          cost: args.cost,
          note: args.note
        })
      ).catch(() => {
        showError(`Failed to update cost for ${selectedIngredient.name}.`)
      })
    }
  }

  const costs: Cost[] = React.useMemo(() => {
    return (
      selectedIngredient?.costs.map((cost) => {
        return {
          id: cost.id,
          note: cost.note || '',
          value: cost.cost,
          measurementUnit: {
            id: cost.measurement.id,
            unit: cost.measurement.unit
          },
          updatedAt: cost.updatedAt
        }
      }) || []
    )
  }, [selectedIngredient?.costs])

  const measurementUnitOptions = React.useMemo(() => {
    return (
      measurements
        ?.filter((measurement) =>
          // TODO: Should be handled from backend.
          [MeasurementType.POUND, MeasurementType.KILOGRAM].includes(
            measurement.type as MeasurementType
          )
        )
        ?.map((measurement) => {
          return {
            id: measurement.id,
            unit: measurement.unit
          }
        }) || []
    )
  }, [measurements])

  return (
    <IngredientForm
      ingredient={ingredient}
      onChange={handleChange}
      costsProps={{
        onCreate: handleCreateCost,
        onDelete: handleDeleteCost,
        onUpdate: handleUpdateCost,
        costs: costs,
        measurementUnitOptions: measurementUnitOptions
      }}
      suppliers={suppliers}
      allergens={allergens}
      tags={tags}
      disabled={disabled}
    />
  )
}
