import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { BoxRow } from 'components/common'
import React from 'react'
import { InView } from 'react-intersection-observer'
import { EntrCard, EntrCardProps } from './EntrCard'

export interface EntrGridProps {
  /**
   * The onClick has been omitted from the EntrCardProps as this will be handled by the Grid
   * similar to the Table component handling the onClick for each row.
   */
  cards: Omit<EntrCardProps, 'onClick'>[]
  allPagesLoaded?: boolean
  onLoadNextPage?: () => void
  noDataMessage?: string
  onCardClick: (id: string) => void
  loading?: boolean
}

/**
 * A scalable Grid with pagination used to display cards.
 */
export const EntrGrid: React.FC<EntrGridProps> = ({
  cards,
  allPagesLoaded,
  onLoadNextPage,
  noDataMessage,
  onCardClick,
  loading = false
}) => {
  const getGridSkeleton = (amount: number) => {
    return Array.from({ length: amount }, (_, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
        <Skeleton
          variant="rectangular"
          height={'250px'}
          width={'300px'}
          sx={{ borderRadius: '12px' }}
        />
      </Grid>
    ))
  }

  const handleInViewChange = (inView: boolean) => {
    if (inView && cards.length > 0 && onLoadNextPage) {
      onLoadNextPage()
    }
  }

  const initialLoading = React.useMemo(() => {
    return loading && cards.length === 0
  }, [loading, cards])

  return (
    <Box sx={{ minHeight: 0, overflow: 'hidden auto' }}>
      {cards.length === 0 && allPagesLoaded && noDataMessage && (
        <BoxRow sx={{ justifyContent: 'center' }}>
          <Typography>{noDataMessage}</Typography>
        </BoxRow>
      )}
      <Grid
        container
        sx={{ justifyContent: 'flex-start', columnGap: '16px', rowGap: '16px' }}
        wrap="wrap"
      >
        {initialLoading && getGridSkeleton(4)}

        {!initialLoading &&
          cards?.map((card) => (
            <Grid item key={card.id}>
              <EntrCard {...card} onClick={() => onCardClick(card.id)} />
            </Grid>
          ))}
      </Grid>
      {!allPagesLoaded && (
        <InView
          as="div"
          onChange={handleInViewChange}
          trackVisibility={true}
          delay={300}
        >
          <div
            style={{
              height: '2rem',
              display: 'block'
            }}
          ></div>
        </InView>
      )}
    </Box>
  )
}
