import { Box } from '@mui/material'
import React from 'react'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { MainColors } from 'styles/colors'

import {
  Avatar,
  AvatarProps
} from 'pages/LabelProofs/LabelProofer/components/Panel/components/Avatar'

export type Owner = Omit<AvatarProps, 'action' | 'size' | 'role'>
export type OwnerProps = AvatarProps

const BlueStarIcon: React.FC = () => {
  return (
    <Box
      sx={{
        width: '13px',
        height: '13px',
        backgroundColor: MainColors.primary,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <StarRoundedIcon sx={{ width: '10px', height: '10px', color: 'white' }} />
    </Box>
  )
}

export const Owner: React.FC<OwnerProps> = ({ ...rest }) => {
  return (
    <Box
      key={`creator-${rest.id}`}
      display={'inline-block'}
      position={'relative'}
      zIndex={10}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '-4px',
          top: '-4px',
          zIndex: '1'
        }}
      >
        <BlueStarIcon />
      </Box>
      <Avatar {...rest} role="Task Owner" />
    </Box>
  )
}
