import { Box } from '@mui/material'
import { Button } from 'components/Button/Button'
import {
  Avatar,
  AvatarProps
} from 'pages/LabelProofs/LabelProofer/components/Panel/components/Avatar'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'

export interface ReviewerProps extends AvatarProps {
  onRemove: (id: string) => void
  removable?: boolean
}

export const Reviewer: React.FC<ReviewerProps> = ({
  onRemove,
  removable = true,
  ...rest
}) => {
  const [removeVisibile, setRemoveVisible] = React.useState(false)

  const handleMouseEnter = () => {
    if (removable) {
      setRemoveVisible(true)
    }
  }

  const handleMouseLeave = () => {
    setRemoveVisible(false)
  }

  return (
    <Box
      key={`reviewer-${rest.id}`}
      display={'inline-block'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      position={'relative'}
    >
      {removeVisibile && (
        <Box
          sx={{ position: 'absolute', right: '-5px', top: '-8px', zIndex: '2' }}
        >
          <Button
            sx={{ width: '10px', height: '10px' }}
            variant="circle"
            color={'danger'}
            onClick={() => onRemove(rest.id)}
          >
            <ClearIcon sx={{ width: '10px', height: '10px' }} />
          </Button>
        </Box>
      )}
      <Avatar {...rest} />
    </Box>
  )
}
