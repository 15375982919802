import React from 'react'
import { NumberField, NumberFieldProps } from './NumberField'
import {
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { BoxRow } from 'components/common'

export interface Unit {
  id: string
  name: string
  abreviation: string
}

export interface UnitAdornmentProps {
  unitOptions: Unit[]
  unit: Unit
  onUnitChange: (unit: Unit) => void
  renderValue?: (value: string) => React.ReactNode
  disabled?: boolean
}

export type NumberUnitFieldProps = NumberFieldProps & UnitAdornmentProps

const UnitAdornment: React.FC<UnitAdornmentProps> = ({
  unit,
  unitOptions,
  onUnitChange,
  renderValue,
  disabled = false
}) => {
  return (
    <InputAdornment position="end">
      <BoxRow
        sx={{
          alignItems: 'center'
        }}
      >
        <Divider orientation="vertical" flexItem style={{}} />
        <Select
          disabled={disabled}
          value={unit.id}
          IconComponent={() => null}
          renderValue={(id) => {
            const selectedUnit = unitOptions.find((option) => option.id === id)
            if (selectedUnit) {
              return (
                <Typography>
                  {renderValue
                    ? renderValue(selectedUnit.abreviation)
                    : selectedUnit.abreviation}
                </Typography>
              )
            }
            return null
          }}
          onChange={(e) => {
            const selectedUnit = unitOptions.find(
              (option) => option.id === e.target.value
            )
            if (selectedUnit) {
              onUnitChange(selectedUnit)
            }
          }}
          sx={{
            boxShadow: 'none',
            border: 'none',
            padding: '0px',
            background: 'transparent',
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
              padding: '0px'
            },
            '&.MuiInputBase-root': {
              border: 'none !important',
              background: 'transparent !important',
              boxShadow: 'none !important',
              textAlign: 'center',
              padding: '0px',
              color: 'rgba(0, 0, 0, 0.54)'
            },
            '.MuiSelect-select': {
              padding: '5px 0px 5px 8px !important'
            }
          }}
        >
          {unitOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.abreviation}
            </MenuItem>
          ))}
        </Select>
      </BoxRow>
    </InputAdornment>
  )
}

export const NumberUnitField: React.FC<NumberUnitFieldProps> = ({
  unit,
  unitOptions,
  onUnitChange,
  renderValue,
  ...rest
}) => {
  return (
    <NumberField
      {...rest}
      endAdornment={
        <UnitAdornment
          unit={unit}
          unitOptions={unitOptions}
          onUnitChange={onUnitChange}
          renderValue={renderValue}
          disabled={rest.disabled}
        />
      }
    />
  )
}
