import {
  QueriesContext,
  QueriesProvider
} from 'components/EntrTable/Filter/QueriesContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { NewProofContainer } from './components/NewProof/NewProofContainer'
import { SearchFiltersContainer } from './components/SearchFilter/SearchFiltersContainer'
import { LabelProofs } from './LabelProofs'
import {
  getCompanyPermissions,
  getProofs
} from 'state/labelproof/proofs/ProofsSice'
import { useLoadNextPage } from 'common/hooks'
import { ProofCardProps } from './components/Grid/ProofCardContent'
import { LabelProofStatusApiResponse } from 'services/apis/label_proofs/LabelProofApiResponse'

const _LabelProofsContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { filters, search } = React.useContext(QueriesContext)
  const { createdBy } = filters

  const { labelProofAnalytics } = React.useContext(AnalyticsContext)

  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const { page, pages } = useAppSelector((state) => state.proofs.proofs)
  const proofItems = useAppSelector((state) => state.proofs.proofs.items)
  const loading = useAppSelector((state) => state.proofs.loadingProofs)
  const companyPermissions = useAppSelector(
    (state) => state.proofs.companyPermissions
  )

  const getProofByPage = React.useCallback(
    (page: number) => {
      if (!companyPermissions) {
        void dispatch(getCompanyPermissions(companyId)).then((action) => {
          if (getCompanyPermissions.fulfilled.match(action)) {
            action.payload.listLabelProofs &&
              void dispatch(
                getProofs({
                  companyId: companyId,
                  fuzzyName: search,
                  createdBy: createdBy,
                  page: page
                })
              )
          }
        })
      } else {
        companyPermissions.listLabelProofs &&
          void dispatch(
            getProofs({
              companyId: companyId,
              fuzzyName: search,
              createdBy: createdBy,
              page: page
            })
          )
      }
    },
    [companyId, search, createdBy, companyPermissions]
  )

  React.useEffect(() => {
    getProofByPage(1)
  }, [companyId, search, createdBy])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback: getProofByPage
  })

  const handleProofClick = (id: string) => {
    navigate(`/labelproofs/${id}`)
  }

  React.useEffect(() => {
    labelProofAnalytics.viewedPage()
  }, [])

  const proofs: ProofCardProps[] = React.useMemo(() => {
    return proofItems.map((proof) => ({
      id: proof.id,
      title: proof.name,
      formulaName: proof.formula.name,
      completedTasks: proof.progress.completedTasks,
      totalTasks: proof.progress.totalTasks,
      creationDate: proof.createdAt,
      completed: proof.progress.status === LabelProofStatusApiResponse.COMPLETED
    }))
  }, [proofItems])

  const canListProofs = React.useMemo(() => {
    return !!companyPermissions?.listLabelProofs
  }, [companyPermissions])

  return (
    <LabelProofs
      filterAndSearch={<SearchFiltersContainer />}
      proofs={proofs}
      newProofAction={<NewProofContainer />}
      onProofClick={handleProofClick}
      loading={loading}
      canListProofs={canListProofs}
      allPagesLoaded={allPagesLoaded}
      onLoadNextPage={nextPageCallback}
    />
  )
}

export const LabelProofsContainer: React.FC = () => {
  return (
    <QueriesProvider>
      <_LabelProofsContainer />
    </QueriesProvider>
  )
}
