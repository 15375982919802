import { Typography } from '@mui/material'
import { ReactComponent as LabelProofFileUploadIcon } from 'assets/LabelProofFileUpload.svg'
import { BoxColumnBasic } from 'components/common'
import FilesUploadButton from 'components/FilesUploadButton/FilesUploadButton'
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone'
import React from 'react'

enum UploadMessage {
  DRAG_FILE = 'Drag a Label design here or',
  UPLOAD_FILE = 'upload a file',
  DISABLED = 'Only the proof creator can upload a design file'
}

export interface UploaderProps {
  uploadFile: (files: File) => void
  uploadDisabled?: boolean
}

export const Uploader: React.FC<UploaderProps> = ({
  uploadFile,
  uploadDisabled = false
}) => {
  const handleUploadFiles = (files: File[]) => {
    if (files.length > 0) {
      uploadFile(files[0])
    }
  }

  return (
    <BoxColumnBasic
      sx={{
        border: '2px dashed #233C583B',
        borderRadius: '20px',
        width: '90%',
        height: '90%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <UploadDropZone
        onDrop={handleUploadFiles}
        zoneStyle={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          borderRadius: '20px'
        }}
        activeZoneAppendedStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        }}
        activeIcon={<></>}
        multiple={false}
      />
      <BoxColumnBasic
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          gap: '35px'
        }}
      >
        {uploadDisabled ? (
          <Typography
            sx={{
              color: 'rgba(35, 60, 88, 0.43)'
            }}
          >
            {UploadMessage.DISABLED}
          </Typography>
        ) : (
          <>
            <UploadIcon />
            <BoxColumnBasic
              sx={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  color: 'rgba(35, 60, 88, 0.43)'
                }}
              >
                {UploadMessage.DRAG_FILE}
              </Typography>
              <FilesUploadButton
                uploadFiles={handleUploadFiles}
                multiple={false}
                variant="text"
                color="primary"
              >
                {UploadMessage.UPLOAD_FILE}
              </FilesUploadButton>
            </BoxColumnBasic>
          </>
        )}
      </BoxColumnBasic>
    </BoxColumnBasic>
  )
}

const UploadIcon: React.FC = () => {
  return (
    <BoxColumnBasic
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        width: '100px',
        height: '100px',
        backgroundColor: '#F4F4F4'
      }}
    >
      <LabelProofFileUploadIcon />
    </BoxColumnBasic>
  )
}
