import { TableCell, TableRow, Typography } from '@mui/material'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { NumberField } from 'components/InputFields/NumberField'
import { BoxRow } from 'components/common'
import React from 'react'

export interface NutrientRowProps {
  nutrient: {
    id: string
    name: string
    amount: number
    unit: string
  }
  disabled: boolean
  calculated: boolean
  onChange: (nutrientId: string, amount: number) => void
  level?: number
}

export const NutrientRow: React.FC<NutrientRowProps> = ({
  nutrient,
  onChange,
  disabled = false,
  level = 0,
  calculated = false
}) => {
  const MAX_DECIMAL = 6
  const DEBOUNCE_TIME = 1000

  return (
    <TableRow key={nutrient.id + '-nutrient-row'}>
      <TableCell align="left">
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            marginLeft: level > 0 ? `${level * 12}px` : '0px'
          }}
        >
          {nutrient.name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <BoxRow justifyContent={'flex-end'} gap={'5px'}>
          {calculated && (
            <InfoBubble
              text="This value is derived from another nutrient and cannot be edited."
              width="300px"
            />
          )}
          <NumberField
            value={nutrient.amount}
            disabled={disabled}
            onChange={(v) => onChange(nutrient.id, v)}
            endAdornment={
              <Typography
                color="rgba(35, 60, 88, 0.43)"
                sx={{ marginLeft: '5px' }}
              >
                {nutrient.unit}
              </Typography>
            }
            maxDecimals={MAX_DECIMAL}
            debounceTime={DEBOUNCE_TIME}
            validation=">=0"
            sx={{ width: '150px' }}
            inputProps={{
              sx: { textAlign: 'right' }
            }}
          />
        </BoxRow>
      </TableCell>
    </TableRow>
  )
}
