import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled
} from '@mui/material'
import { BoxRowBasic } from 'components/common'
import React from 'react'

const UnitTypography = styled(Typography)`
  margin-left: 3px;
  font-size: 12px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

const formatNumber = (value: number) => {
  return value ? value.toFixed(2) : '0'
}

export interface IngredientBreakdownProps {
  ingredient: {
    id: string
    name: string
  }
  nutrient: {
    amount: number
    unit: string
  }
}

export interface ModifierProps {
  name: string
  amount: number
  unit: string
}

export interface IngredientsBreakdownProps {
  ingredientsBreakdown: IngredientBreakdownProps[]
  modifiers?: ModifierProps[]
}

export const IngredientsBreakdown: React.FC<IngredientsBreakdownProps> = ({
  ingredientsBreakdown = [],
  modifiers = []
}) => {
  return (
    <Box
      borderRadius="8px"
      border="1px solid rgba(0, 0, 0, 0.05)"
      boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.03)"
      padding={'5px'}
    >
      <Table>
        <TableBody>
          {ingredientsBreakdown
            .sort((a, b) => b.nutrient.amount - a.nutrient.amount)
            .map((ib) => (
              <TableRow key={ib.ingredient.id}>
                <TableCell sx={{ padding: '4px' }}>
                  <Typography>{ib.ingredient.name}</Typography>
                </TableCell>
                <TableCell sx={{ padding: '4px' }} align="right">
                  <BoxRowBasic sx={{ justifyContent: 'flex-end' }}>
                    <Typography>{formatNumber(ib.nutrient.amount)}</Typography>
                    <UnitTypography>{ib.nutrient.unit}</UnitTypography>
                  </BoxRowBasic>
                </TableCell>
              </TableRow>
            ))}
          {modifiers.map((m) => (
            <TableRow key={m.name}>
              <TableCell sx={{ padding: '4px' }}>
                <Typography>{m.name}</Typography>
              </TableCell>
              <TableCell sx={{ padding: '4px' }} align="right">
                <BoxRowBasic sx={{ justifyContent: 'flex-end' }}>
                  <Typography>{formatNumber(m.amount)}</Typography>
                  <UnitTypography>{m.unit}</UnitTypography>
                </BoxRowBasic>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default IngredientsBreakdown
