import { Backdrop, Box } from '@mui/material'
import { Button } from 'components/Button/Button'
import React from 'react'
import { NewProofPopper, NewProofPopperProps } from './NewProofPopper'

export const NewProofButton: React.FC<
  Omit<NewProofPopperProps, 'anchorEl' | 'open'>
> = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <>
      <Backdrop
        open={open}
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.25)', zIndex: 2 }}
        onClick={handleClose}
      />
      <Box>
        <Button color="primary" onClick={handleOpen} disabled={props.disabled}>
          New Proof
        </Button>
        <NewProofPopper {...props} anchorEl={anchorEl} open={open} />
      </Box>
    </>
  )
}
