import { IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material'
import { BoxRow } from 'components/common'
import {
  FormulatorNumberField,
  MeasurementInput,
  UnstyledInput
} from 'pages/Formulator/FormulatorStyles'
import React from 'react'

interface MeasurementProps {
  id: string
  unit: string
}

export interface IngredientQuantityEditorProps {
  amount: {
    value: number
    percentage: number
    onUpdate: (amount: number) => void
  }
  measurement: {
    id: string
    unit: string
    onUpdate: (measurementId: string) => void
    getAvailableMeasurements?: () => Promise<MeasurementProps[]>
  }
  showPercentages?: boolean
  disabled?: boolean
}

export const IngredientQuantityEditor: React.FC<
  IngredientQuantityEditorProps
> = ({ amount, measurement, showPercentages = true, disabled = false }) => {
  const theme = useTheme()
  const [unitMenuAnchorEl, setUnitMenuAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const [unit, setUnit] = React.useState<string>('g')
  const [availableMeasurements, setAvailableMeasurements] = React.useState<
    MeasurementProps[]
  >([])

  React.useEffect(() => {
    if (measurement) {
      setUnit(measurement.unit)
    }
  }, [measurement])

  const handleUnitMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setUnitMenuAnchorEl(event.currentTarget)
    if (measurement.getAvailableMeasurements) {
      void measurement.getAvailableMeasurements().then((measurements) => {
        setAvailableMeasurements(measurements)
      })
    }
  }

  const handleUnitMenuClose = () => {
    setUnitMenuAnchorEl(null)
  }

  const handleQuantityChange = (value: number) => {
    amount.onUpdate(value)
  }

  const handleUnitClick = (measurementId: string) => {
    if (measurement.onUpdate) {
      measurement.onUpdate(measurementId)
    }
  }

  return (
    <BoxRow>
      {showPercentages && (
        <UnstyledInput
          disabled={true}
          value={amount.percentage.toFixed(2)}
          sx={{
            borderRadius: '8px 0px 0px 8px',
            flex: 3
          }}
          endAdornment={
            <Typography
              marginLeft={'0.25em'}
              color={theme.palette.tertiary.main}
            >
              %
            </Typography>
          }
          inputProps={{ style: { textAlign: 'right' } }}
        />
      )}
      <FormulatorNumberField
        onChange={handleQuantityChange}
        value={amount.value}
        validation=">=0"
        maxDecimals={6}
        sx={{
          borderLeft: showPercentages ? '2px solid rgba(0, 0, 0, 0.06)' : '0px',
          borderRadius: showPercentages ? '0px' : '8px 0px 0px 8px'
        }}
        boxSx={{
          flex: 4
        }}
        fullWidth={false}
        debounceTime={500}
        autoFocus
      />
      <MeasurementInput
        sx={{
          borderRadius: '0px 8px 8px 0px',
          flex: 3
        }}
      >
        <IconButton
          sx={{ padding: '4px 0px 5px 0px' }}
          disabled={disabled}
          disableRipple
          size="small"
          onClick={(e) => {
            handleUnitMenuClick(e)
          }}
          aria-label="select unit"
        >
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '23px',
              fontweight: '600'
            }}
          >
            {unit}
          </Typography>
        </IconButton>
        <Menu
          anchorEl={unitMenuAnchorEl}
          open={Boolean(unitMenuAnchorEl)}
          onClose={handleUnitMenuClose}
        >
          {availableMeasurements.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === measurement?.id}
              onClick={() => {
                handleUnitClick(option.id)
                handleUnitMenuClose()
              }}
            >
              {option.unit}
            </MenuItem>
          ))}
        </Menu>
      </MeasurementInput>
    </BoxRow>
  )
}
