import { Backdrop, Box, Tooltip, Typography } from '@mui/material'
import { ColumnConfiguration } from 'components/EntrTable/EntrTable'
import {
  SearchableTablePopper,
  SearchProps
} from 'components/SearchableTablePopper/SearchableTablePopper'
import React from 'react'

interface FormulasTableRow {
  id: string
  name: string
  friendlyId?: string
}

export interface NewProofPopperProps {
  search: SearchProps
  open: boolean
  anchorEl: HTMLElement | null
  data: FormulasTableRow[]
  disabled?: boolean
  allPagesLoaded?: boolean
  onRowClick: (id: string) => void
  onLoadNextPage?: () => void
}

export const NewProofPopper: React.FC<NewProofPopperProps> = ({
  search,
  open,
  anchorEl,
  data,
  allPagesLoaded,
  onRowClick,
  onLoadNextPage
}) => {
  const columnConfigs: ColumnConfiguration<FormulasTableRow>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '70%' },
      field: (formula) => formula.name,
      customRender: (formula) => (
        <Tooltip title={formula.name} enterNextDelay={3000}>
          <Typography sx={{ fontWeight: 'bold' }} noWrap>
            {formula.name}
          </Typography>
        </Tooltip>
      )
    },
    {
      title: 'Friendly ID',
      field: (formula) => formula.friendlyId ?? '',
      customRender: (formula) => (
        <Tooltip title={formula.friendlyId} enterNextDelay={3000}>
          <Typography variant="body2" sx={{ textAlign: 'right' }}>
            {formula.friendlyId ? `#${formula.friendlyId}` : ''}
          </Typography>
        </Tooltip>
      ),
      fieldStyles: {
        width: '30%'
      },
      titleSx: {
        justifyContent: 'flex-end'
      }
    }
  ]

  return (
    <SearchableTablePopper
      title="Select a formula for the label proof"
      search={search}
      tableProps={{
        data: data,
        columnConfigs: columnConfigs,
        allPagesLoaded: allPagesLoaded,
        onLoadNextPage: onLoadNextPage,
        onRowClick: (row) => {
          onRowClick(row.id)
        },
        noDataMessage: 'No formulas found'
      }}
      popperProps={{
        open: open,
        anchorEl: anchorEl
      }}
    />
  )
}
