import React from 'react'
import { Nutrients } from './Nutrients'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import {
  getSimpleIngredientNutrients,
  updateSimpleIngredient,
  updateSimpleIngredientNutrient
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import {
  getLiquidMeasurements,
  getMeasurements,
  getSolidMeasurements
} from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import { NutrientRowGroupProps } from './components/NutrientRowGroup'
import { toTableNutrientsRowGroup } from './NutrientsMapper'

export interface NutrientsContainerProps {
  ingredientId: string
  disabled?: boolean
}

export const NutrientsContainer: React.FC<NutrientsContainerProps> = ({
  ingredientId,
  disabled
}) => {
  const dispatch = useAppDispatch()
  const { showError } = React.useContext(SnackbarContext)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const currentIngredientNutrients = useAppSelector(
    (state) => state.simpleIngredients.currentSimpleIngredientNutrients
  )
  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const liquidMeasurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.liquidMeasurements
  )
  const solidMeasurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.solidMeasurements
  )

  const [searchQuery, setSearchQuery] = React.useState<string>('')

  /**
   * Load the ingredient nutrients.
   */
  React.useEffect(() => {
    if (ingredientId) {
      void dispatch(
        getSimpleIngredientNutrients({
          companyId: currentCompany.id,
          simpleIngredientId: ingredientId
        })
      )
    }
  }, [ingredientId])

  // load measurements
  React.useEffect(() => {
    if (solidMeasurements === undefined) {
      void dispatch(
        getSolidMeasurements({
          companyId: currentCompany.id
        })
      )
    }
    if (liquidMeasurements === undefined) {
      void dispatch(
        getLiquidMeasurements({
          companyId: currentCompany.id
        })
      )
    }
    if (measurements === undefined) {
      void dispatch(
        getMeasurements({
          companyId: currentCompany.id
        })
      )
    }
  }, [solidMeasurements, liquidMeasurements, measurements, currentCompany.id])

  const onMeasurementOrScalePreferenceChange = (
    measurementPreferenceId?: string,
    scalePreference?: number
  ) => {
    if (
      (selectedIngredient?.measurementPreference?.id ===
        measurementPreferenceId &&
        selectedIngredient?.scalePreference === scalePreference) ||
      !scalePreference
    ) {
      return
    }
    void dispatch(
      updateSimpleIngredient({
        companyId: currentCompany.id,
        simpleIngredientId: ingredientId,
        measurementPreferenceId,
        scalePreference
      })
    ).then((action) => {
      if (updateSimpleIngredient.rejected.match(action)) {
        const errorMessage = action.error.message ? `Upon scaling, ${action.error.message}` : 'Failed to update ingredient'
        showError(errorMessage)
      }
    })
  }

  const onAmountChange = React.useCallback(
    (nutrientId: string, amount: number) => {
      if (ingredientId) {
        void dispatch(
          updateSimpleIngredientNutrient({
            companyId: currentCompany.id,
            simpleIngredientId: ingredientId,
            nutrientType: nutrientId, // Done on purpose. To be replaced by Nutrient Id once API gets updated.
            amount
          })
        )
          .unwrap()
          .catch(() => {
            showError(`Failed to update nutrient`)
          })
      }
    },
    [ingredientId, currentCompany.id]
  )

  const filterNutrientRowGroups = (
    nutrientRowGroups: NutrientRowGroupProps[],
    searchQuery: string
  ) => {
    return nutrientRowGroups.reduce((acc: NutrientRowGroupProps[], nrg) => {
      const filteredNutrientRows = nrg.nutrientRows.filter((nr) =>
        nr.nutrient.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      if (filteredNutrientRows.length > 0) {
        // Only add nrg to the accumulator if filteredNutrientRows is not empty.
        acc.push({ ...nrg, nutrientRows: filteredNutrientRows })
      }
      return acc
    }, [])
  }

  const nutrientTableRowGroups = React.useMemo(() => {
    if (currentIngredientNutrients) {
      const result = toTableNutrientsRowGroup(
        currentIngredientNutrients,
        onAmountChange
      )
      if (searchQuery) {
        return filterNutrientRowGroups(result, searchQuery)
      }
      return result
    }
    return []
  }, [currentIngredientNutrients, searchQuery])

  const onNutrientSearchChange = React.useCallback((searchQuery: string) => {
    setSearchQuery(searchQuery)
  }, [])

  const unitOptions = React.useMemo(() => {
    if (!measurements) {
      return []
    }

    const volumetricUnitsSupported = !!(
      selectedIngredient?.calculatedDensity ||
      selectedIngredient?.densityOverride
    )

    const filteredMeasurements = volumetricUnitsSupported
      ? measurements
      : solidMeasurements || []

    return filteredMeasurements.map((m) => {
      return { id: m.id, name: m.name, abreviation: m.unit }
    })
  }, [
    measurements,
    solidMeasurements,
    liquidMeasurements,
    selectedIngredient?.measurementPreference?.unit,
    selectedIngredient?.calculatedDensity,
    selectedIngredient?.densityOverride
  ])

  return (
    <Nutrients
      disabled={disabled}
      nutrientTableRowGroups={nutrientTableRowGroups}
      search={{
        value: searchQuery,
        onChange: onNutrientSearchChange
      }}
      basedOn={{
        scale: selectedIngredient?.scalePreference || 100,
        unit: {
          id: selectedIngredient?.measurementPreference?.id || '',
          name: selectedIngredient?.measurementPreference?.name || '',
          abreviation: selectedIngredient?.measurementPreference?.unit || ''
        },
        unitOptions: unitOptions,
        onUnitChange: (unit) => {
          onMeasurementOrScalePreferenceChange(
            unit.id,
            selectedIngredient?.scalePreference
          )
        },
        onScaleChange: (scale) => {
          onMeasurementOrScalePreferenceChange(
            selectedIngredient?.measurementPreference?.id,
            scale
          )
        }
      }}
    />
  )
}
