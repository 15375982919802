import { Box, Skeleton, Typography } from '@mui/material'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'
import { BoxColumnBasic, BoxRow, BoxRowBasic } from 'components/common'
import { MenuButton, MenuItem } from 'components/MenuButton/MenuButton'
import React from 'react'
import { CommentText } from './CommentText'
import { TextParserFunction } from './TextParsers'
import { DateWithTooltip } from 'components/DateWithTooltip/DateWithTooltip'

export interface CommentProps {
  id: string
  text: string
  createdBy?: {
    name: string
    avatarSrc?: string
    isOwner: boolean
  }
  timestamp: string
  repliedComment?: {
    id: string
    text: string
    createdByName?: string
    onClick?: (id: string) => void
    deleted: boolean
  }
  edited: boolean
  deleted: boolean
  editable?: boolean
  deletable?: boolean
  parsers?: TextParserFunction[]
  highlight?: boolean
  onReplyClick: (id: string) => void
  onEditClick: (id: string) => void
  onDeleteClick: (id: string) => void
}

export const CommentSkeleton = () => {
  return (
    <BoxRowBasic gap="1rem">
      <BoxColumnBasic>
        <Skeleton variant="circular" height={40} width={40} />
      </BoxColumnBasic>
      <BoxColumnBasic gap={1} sx={{ flexGrow: 1 }}>
        <BoxRowBasic gap={1}>
          <Skeleton variant="rectangular" height={10} sx={{ flexGrow: 0.25 }} />
        </BoxRowBasic>
        <Skeleton variant="rectangular" height={30} sx={{ flexGrow: 1 }} />
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}

export const Comment: React.FC<CommentProps> = ({
  id,
  createdBy,
  timestamp,
  text,
  parsers,
  repliedComment,
  edited,
  deleted,
  highlight,
  onReplyClick,
  onEditClick,
  onDeleteClick,
  editable = true,
  deletable = true
}) => {
  const [onHover, setOnHover] = React.useState(false)

  const menuItems = React.useMemo(() => {
    const items: MenuItem[] = []

    if (!deleted) {
      items.push({
        itemTitle: 'Reply',
        itemCallback: () => onReplyClick(id)
      })
    }

    if (editable) {
      items.push({
        itemTitle: 'Edit',
        itemCallback: () => onEditClick(id)
      })
    }

    if (deletable) {
      items.push({
        itemTitle: 'Delete',
        itemStyle: {
          color: 'var(--error, #D03838)'
        },
        itemCallback: () => onDeleteClick(id)
      })
    }

    return items
  }, [
    createdBy?.isOwner,
    id,
    onEditClick,
    onDeleteClick,
    onReplyClick,
    deletable,
    editable,
    deleted
  ])

  return (
    <BoxRowBasic
      gap="1rem"
      sx={{
        width: '100%',
        borderRadius: '8px',
        backgroundColor: highlight ? '#59D4FB26' : 'transparent',
        padding: '10px'
      }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <BoxColumnBasic>
        <AvatarInitials
          name={createdBy?.name || ''}
          avatarSrc={createdBy?.avatarSrc || ''}
          size={40}
        />
      </BoxColumnBasic>
      <BoxColumnBasic sx={{ gap: '5px', width: '100%' }}>
        <BoxRow sx={{ justifyContent: 'space-between' }}>
          <BoxRowBasic sx={{ gap: '8px', alignItems: 'baseline' }}>
            <Typography variant="subtitle1" fontWeight={600}>
              {createdBy?.name || 'Unknown'}
            </Typography>
            <DateWithTooltip date={timestamp} />
            {edited && !deleted && (
              <Typography
                sx={{
                  fontStyle: 'italic',
                  color: 'gray',
                  fontSize: '11px'
                }}
              >
                (edited)
              </Typography>
            )}
          </BoxRowBasic>
          {menuItems.length > 0 && (
            <MenuButton menuItems={menuItems} showButton={onHover} />
          )}
        </BoxRow>
        {repliedComment && (
          <Box
            sx={{
              padding: '4px 6px',
              borderRadius: '4px',
              background: '#F6F5F4',
              width: 'fit-content',
              cursor: repliedComment.onClick ? 'pointer' : 'default'
            }}
            onClick={() => repliedComment.onClick?.(repliedComment.id)}
          >
            <CommentText
              text={repliedComment.text}
              parsers={parsers}
              variant={repliedComment.deleted ? 'deleted-replied' : 'replied'}
            />
          </Box>
        )}
        <CommentText
          text={text}
          parsers={parsers}
          variant={deleted ? 'deleted' : 'normal'}
        />
      </BoxColumnBasic>
    </BoxRowBasic>
  )
}
