import { Skeleton, Typography } from '@mui/material'
import { BoxRow } from 'components/common'
import React from 'react'
import { ReactComponent as BackArrowIcon } from 'assets/BackArrow.svg'
import { Button } from 'components/Button/Button'
import { MenuButton } from 'components/MenuButton/MenuButton'
import {
  SavingStatus,
  SavingStatusIndicator
} from 'components/SavingStatusIndicator/SavingStatusIndicator'
import { ReactComponent as FormulasIcon } from 'assets/FormulasOutline.svg'
import { EditableText } from 'components/EditableText/EditableText'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

interface BackButtonProps {
  onClick?: () => void
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <BoxRow>
      <Button
        variant="square"
        color="primary"
        onClick={onClick}
        disabled={onClick === undefined}
        size="medium"
      >
        <BackArrowIcon />
      </Button>
      <Typography style={{ marginLeft: '10px' }} variant="subtitle1">
        Back to All Proofs
      </Typography>
    </BoxRow>
  )
}

const Placeholder = () => {
  return (
    <BoxRow justifyContent={'space-between'} alignContent={'center'}>
      <BackButton />
      <BoxRow gap="5px">
        <Skeleton variant="rectangular" height={25} width={300} />
      </BoxRow>
      <BoxRow gap="12px">
        <Skeleton variant="rectangular" height={25} width={125} />
        <Skeleton variant="rectangular" height={32} width={38} />
      </BoxRow>
    </BoxRow>
  )
}

export interface TopBarProps {
  onBackButtonClick: () => void
  onDeleteClick: () => void
  saving: boolean
  loading: boolean
  formulaName: string
  proofName: string
  renamable?: boolean
  deletable?: boolean
  onProofNameChanged: (name: string) => void
}

export const TopBar: React.FC<TopBarProps> = ({
  onBackButtonClick,
  onDeleteClick,
  saving,
  loading,
  formulaName,
  proofName,
  onProofNameChanged,
  renamable = true,
  deletable = true
}) => {
  return (
    <>
      {loading && <Placeholder />}
      {!loading && (
        <BoxRow justifyContent={'space-between'} alignContent={'center'}>
          <BackButton onClick={onBackButtonClick} />
          <BoxRow gap="5px">
            <FormulasIcon />
            <Typography variant="subtitle1">{formulaName}</Typography>
            <KeyboardArrowRightIcon style={{ width: '16px', height: '16px' }} />
            <EditableText
              initialValue={proofName}
              placeholder="Untitled Proof"
              saveAction={onProofNameChanged}
              variant="subtitle1"
              sx={{ fontWeight: '600' }}
              disabled={!renamable}
            />
          </BoxRow>
          <BoxRow gap="12px">
            <SavingStatusIndicator
              status={saving ? SavingStatus.SAVING : SavingStatus.SAVED}
            />
            <MenuButton
              buttonStyle={{
                height: '100%'
              }}
              menuItems={[
                {
                  itemTitle: 'Delete',
                  itemStyle: {
                    color: 'var(--error, #D03838)'
                  },
                  disabled: !deletable,
                  itemCallback: onDeleteClick
                }
              ]}
            />
          </BoxRow>
        </BoxRow>
      )}
    </>
  )
}
