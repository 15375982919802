import React from 'react'

import { Tooltip } from '@mui/material'
import { PATHS } from 'common/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuContainer, StyledSideBar } from './NavBarStyles'

import { ReactComponent as AppleFilled } from 'assets/AppleFilled.svg'
import { ReactComponent as Apple } from 'assets/AppleOutline.svg'
import { ReactComponent as Cog } from 'assets/CogOutline.svg'
import { ReactComponent as Help } from 'assets/HelpCircle.svg'
import { ReactComponent as FormulasFilled } from 'assets/FormulasFilled.svg'
import { ReactComponent as Formulas } from 'assets/FormulasOutline.svg'
import { ReactComponent as SuppliersFilled } from 'assets/SuppliersFilled.svg'
import { ReactComponent as Suppliers } from 'assets/SuppliersOutline.svg'
import { ReactComponent as TargetFilled } from 'assets/TargetFilled.svg'
import { ReactComponent as Target } from 'assets/TargetOutline.svg'
import { ReactComponent as LabelProof } from 'assets/LabelProofOutline.svg'
import { ReactComponent as LabelProofFilled } from 'assets/LabelProofFilled.svg'

import { PageColors } from 'styles/colors'
import { BrandSwitcher } from '../BrandSwitcher/BrandSwitcher'
import { Tab } from 'components/Tabs/Tab/Tab'
import { Tabs } from 'components/Tabs/Tabs'

export const Navbar: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // Maps paths to icons for easier management.
  const tabsInfo = [
    {
      path: PATHS.FORMULAS,
      activeLabel: <FormulasFilled />,
      unactiveLabel: <Formulas />,
      tooltip: 'Formulas',
      bgColor: PageColors.formulas
    },
    {
      path: PATHS.INGREDIENTS,
      activeLabel: <AppleFilled />,
      unactiveLabel: <Apple />,
      tooltip: 'Ingredients',
      bgColor: PageColors.ingredients
    },
    {
      path: PATHS.SUPPLIERS,
      activeLabel: <SuppliersFilled />,
      unactiveLabel: <Suppliers />,
      tooltip: 'Suppliers',
      bgColor: PageColors.suppliers
    },
    {
      path: PATHS.TARGETS,
      activeLabel: <TargetFilled />,
      unactiveLabel: <Target />,
      tooltip: 'Targets',
      bgColor: PageColors.targets
    },
    // {
    //   path: PATHS.LABEL_PROOFS,
    //   activeLabel: <LabelProofFilled />,
    //   unactiveLabel: <LabelProof />,
    //   tooltip: 'Label Proofs',
    //   bgColor: PageColors.labelProofs
    // },
    {
      path: PATHS.HELP,
      activeLabel: <Help />,
      unactiveLabel: <Help />,
      tooltip: 'Help',
      bgColor: PageColors.help
    },
    {
      path: PATHS.PROFILE,
      activeLabel: <Cog />,
      unactiveLabel: <Cog />,
      tooltip: 'Settings',
      bgColor: PageColors.settings
    }
  ]

  const currentTab = tabsInfo.findIndex((tab) =>
    location.pathname.includes(tab.path)
  )

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (tabsInfo[newValue].path.startsWith('http')) {
      window.open(tabsInfo[newValue].path, '_blank')
    } else {
      navigate(tabsInfo[newValue].path)
    }
  }

  return (
    <StyledSideBar>
      <MenuContainer>
        <Tabs
          value={currentTab === -1 ? 0 : currentTab}
          onChange={handleChange}
          orientation="vertical"
          sx={{
            flexGrow: 1,
            marginBottom: '12px',
            '& .MuiTabs-flexContainerVertical': {
              height: '100%',
              gap: '12px'
            },
            '& .MuiTabs-flexContainerVertical > :nth-last-child(2)': {
              marginTop: 'auto' // Push the second last child to the bottom.
            }
          }}
        >
          {tabsInfo.map((tab, index) => (
            <Tooltip
              key={`navbar-tooltip-${index}`}
              title={tab.tooltip}
              placement="right"
              arrow
            >
              <Tab
                key={`navbar-tab-${index}`}
                variant="square"
                label={
                  currentTab === index ? tab.activeLabel : tab.unactiveLabel
                }
                sx={{
                  '&.Mui-selected': {
                    background: tab.bgColor
                  },
                  background: 'transparent',
                  '&:hover': {
                    backgroundColor: tab.bgColor
                  }
                }}
              />
            </Tooltip>
          ))}
        </Tabs>
        <BrandSwitcher />
      </MenuContainer>
    </StyledSideBar>
  )
}
