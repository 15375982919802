import { Input, InputProps } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'
import React, { useState, useEffect } from 'react'
import { LabelTypography } from '../common'

interface TextFieldProps extends Omit<InputProps, 'onChange'> {
  label?: string
  labelIcon?: React.ReactNode
  onChange?: (value: string) => void
  value: string // Make value a required prop
  debounceTime?: number
  scale?: 'small' | 'medium' | 'large'
}

export const TextField: React.FC<TextFieldProps> = ({
  label = '',
  onChange,
  value,
  scale = 'medium',
  labelIcon,
  debounceTime = 0,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange) {
        onChange(inputValue)
      }
    },
    debounceTime
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setInternalValue(inputValue)
    handleInputChangeDebounced(inputValue.trim())
  }

  return (
    <div>
      {label && (
        <LabelTypography variant="subtitle2">
          {label} {rest.required && '*'} {labelIcon}
        </LabelTypography>
      )}
      <Input
        fullWidth
        value={internalValue}
        onChange={handleInputChange}
        {...rest}
        sx={{
          boxSizing: 'border-box',
          background: '#FDFDFD',
          border: '1px solid rgba(161, 171, 183, 0.3)',
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          position: 'relative',
          display: 'flex',
          overflow: 'hidden',

          '& input': {
            width: '100%',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize:
              scale === 'large' ? '20px' : scale === 'small' ? '14px' : '16px',
            lineHeight: '20px',
            color: '#16191C',
            padding: '0px'
          }
        }}
      />
    </div>
  )
}
