import React from 'react'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { BasicDocument } from 'models/Document'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { DocumentsPanel } from 'components/DocumentsPanel/DocumentsPanel'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'
import { DetailedSupplier } from 'models/Supplier'
import {
  deleteSupplierDocument,
  downloadSupplierDocument,
  getSupplierDocuments,
  previewSupplierDocument,
  uploadSupplierDocument
} from 'state/suppliers/documents/SupplierDocumentsSlice'

interface SuppliersDocumentsPanelProps {
  supplier: DetailedSupplier
}

export const SuppliersDocumentsPanel: React.FC<
  SuppliersDocumentsPanelProps
> = ({ supplier }) => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const supplierDocuments = useAppSelector(
    (state) => state.supplierDocuments.supplierDocuments
  )

  const { showError } = React.useContext(SnackbarContext)
  const { hideLoading } = React.useContext(LoadingStateContext)

  const getDocuments = () => {
    void dispatch(
      getSupplierDocuments({
        companyId: currentCompany.id,
        supplierId: supplier.id
      })
    ).then((action) => {
      hideLoading()
      if (getSupplierDocuments.rejected.match(action)) {
        showError('There was a problem loading the documents')
      }
    })
  }

  const handleUploadDocument = (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => {
    return dispatch(
      uploadSupplierDocument({
        companyId: currentCompany.id,
        supplierId: supplier.id,
        file: file,
        uploadProgressListener: (progressEvent) => {
          progressListener(progressEvent.progress)
        }
      })
    ).then(() => {})
  }

  const handlePreviewDocument = (file: BasicDocument) => {
    if (supplier) {
      return dispatch(
        previewSupplierDocument({
          companyId: currentCompany.id,
          file: file,
          supplierId: supplier.id
        })
      )
        .unwrap()
        .then((res) => {
          return res.path
        })
    } else {
      return Promise.resolve('')
    }
  }

  const handleDownloadDocument = (file: BasicDocument) => {
    return dispatch(
      downloadSupplierDocument({
        companyId: currentCompany.id,
        supplierId: supplier.id,
        fileName: file.name
      })
    ).then(() => {})
  }

  const handleDeleteDocument = (file: BasicDocument): Promise<void> => {
    return dispatch(
      deleteSupplierDocument({
        companyId: currentCompany.id,
        supplierId: supplier.id,
        fileName: file.name
      })
    ).then(() => {})
  }

  React.useEffect(() => {
    if (currentCompany.id) {
      getDocuments()
    }
  }, [currentCompany.id, supplier.id])

  return (
    <DocumentsPanel
      documents={supplierDocuments}
      onDeleteDocument={handleDeleteDocument}
      onDownloadDocument={handleDownloadDocument}
      onPreviewDocument={handlePreviewDocument}
      onUploadDocument={handleUploadDocument}
      refresh={getDocuments}
    />
  )
}
