import { Skeleton, TableCell, TableRow, Typography } from '@mui/material'
import { convertToFixedFloat } from 'common/utils'
import { BoxRowBasic } from 'components/common'
import React from 'react'
import { UnitTypography } from '../../../OverrideStyles'
import { OverrideTextField } from '../../common/OverrideTextField'

export interface ActiveIngredientRowProps {
  activeIngredient: {
    id: string
    name: string
    amount: number
    unit: string
  }
  overrides: {
    name?: string
    amount?: string
    onChange: (id: string, name?: string, amount?: string) => void
  }
}

const formatNumber = (value?: number) => {
  if (value === undefined || value === null) {
    return ''
  }
  return convertToFixedFloat(value, 2)
}

export const ActiveIngredientRowSkeleton: React.FC = () => {
  return (
    <TableRow>
      {Array.from({ length: 4 }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton variant="text" width="100%" height="37px" />
        </TableCell>
      ))}
    </TableRow>
  )
}

export const ActiveIngredientRow: React.FC<ActiveIngredientRowProps> = ({
  activeIngredient,
  overrides
}) => {
  return (
    <TableRow key={activeIngredient.id + '-active-ingredient-row'}>
      {/* Original nutrients. */}
      <TableCell
        align="left"
        sx={{
          padding: '4px',
          position: 'sticky',
          left: 0,
          zIndex: 2,
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)'
        }}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '14px'
          }}
        >
          {activeIngredient.name}
        </Typography>
      </TableCell>
      <TableCell align="left" sx={{ padding: '4px' }}>
        <BoxRowBasic>
          <Typography fontSize={'14px'}>
            {formatNumber(activeIngredient.amount)}
          </Typography>
          <UnitTypography>{activeIngredient.unit}</UnitTypography>
        </BoxRowBasic>
      </TableCell>

      {/* Overrides. */}
      <TableCell align="left" sx={{ padding: '4px' }}>
        <OverrideTextField
          scale="small"
          value={overrides?.name ?? ''}
          onChange={(value) =>
            overrides?.onChange(activeIngredient.id, value, undefined)
          }
          multiline
          originalValue={activeIngredient.name}
        />
      </TableCell>
      <TableCell align="left" sx={{ padding: '4px' }}>
        <OverrideTextField
          scale="small"
          value={overrides?.amount || ''}
          onChange={(value) => {
            overrides?.onChange(activeIngredient.id, undefined, value)
          }}
          multiline
          originalValue={`${formatNumber(activeIngredient.amount)} ${
            activeIngredient.unit
          }`}
        />
      </TableCell>
    </TableRow>
  )
}
