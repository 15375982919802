import { Typography } from '@mui/material'
import { Select } from 'components/Select/Select'
import { BoxColumnBasic, BoxRow, SelectOption } from 'components/common'
import { SubFormulaStatementFormat } from 'models/Formula'
import { FormulaIngredientsFormatPreview } from 'models/FormulaLabel'
import React from 'react'
import { EditorSection } from '../../../common/EditorSection'

interface SubFormulasProps {
  formulaIngredientsListing: FormulaIngredientsFormatPreview[]
  formatOptions: SelectOption<SubFormulaStatementFormat>[]
  onFormatChange: (id: string, format: SubFormulaStatementFormat) => void
}

export const SubFormulas: React.FC<SubFormulasProps> = ({
  formulaIngredientsListing,
  formatOptions,
  onFormatChange
}) => {
  if (formulaIngredientsListing.length === 0) {
    return null
  }

  return (
    <EditorSection title="Sub-Formulas">
      <BoxColumnBasic sx={{ gap: '10px' }}>
        {formulaIngredientsListing.map((ingredient) => (
          <BoxRow
            key={ingredient.id}
            sx={{ gap: '5px', justifyContent: 'space-between' }}
          >
            <Typography>{ingredient.name}</Typography>
            <Select
              options={formatOptions}
              value={ingredient.format}
              onChange={(format) => onFormatChange(ingredient.id, format)}
            />
          </BoxRow>
        ))}
      </BoxColumnBasic>
    </EditorSection>
  )
}
