import {
  AddReviewerApiResponse,
  TaskApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import {
  AddReviewerRequest,
  CreateTaskRequest,
  DeleteTaskRequest,
  GetReviewerOptionsRequest,
  GetTasksRequest,
  RemoveReviewerRequest,
  ReviewTaskRequest,
  UpdateTaskRequest
} from './ReviewRequest'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserApi } from 'services/apis/user/UserApi'
import { BasicUser } from 'models/User'

export interface ReviewState {
  tasks: TaskApiResponse[]
  reviewerOptions: BasicUser[]
  loadingTasks: boolean
  updatingTask: boolean
  addingTask: boolean
  loadingReviewerOptions: boolean
}

const initialState: ReviewState = {
  tasks: [],
  reviewerOptions: [],
  loadingTasks: true,
  updatingTask: false,
  addingTask: false,
  loadingReviewerOptions: true
}

export const getTasks = createAsyncThunk(
  'labelproof/review/getTasks',
  async ({ companyId, proofId }: GetTasksRequest) => {
    return await LabelProofsApi.getTasks(companyId, proofId)
  }
)

export const createTask = createAsyncThunk(
  'labelproof/review/createTask',
  async ({ companyId, proofId, name }: CreateTaskRequest) => {
    return await LabelProofsApi.createTask(companyId, proofId, { name })
  }
)

export const updateTask = createAsyncThunk(
  'labelproof/review/updateTask',
  async ({ companyId, proofId, taskId, name }: UpdateTaskRequest) => {
    return await LabelProofsApi.updateTask(companyId, proofId, taskId, { name })
  }
)

export const deleteTask = createAsyncThunk(
  'labelproof/review/deleteTask',
  async ({ companyId, proofId, taskId }: DeleteTaskRequest) => {
    return await LabelProofsApi.deleteTask(companyId, proofId, taskId).then(
      () => {
        return taskId
      }
    )
  }
)

export const reviewTask = createAsyncThunk(
  'labelproof/review/reviewTask',
  async ({ companyId, proofId, taskId, approved }: ReviewTaskRequest) => {
    return await LabelProofsApi.reviewTask(companyId, proofId, taskId, {
      approved
    })
  }
)

export const getReviewerOptions = createAsyncThunk(
  'labelproof/review/getReviewerOptions',
  async ({ companyId }: GetReviewerOptionsRequest) => {
    return (await UserApi.getCompanyUsers(companyId)).map(
      (userRole) => userRole.user
    )
  }
)

export const addReviewer = createAsyncThunk(
  'labelproof/review/addReviewer',
  async ({ companyId, proofId, taskId, reviewerId }: AddReviewerRequest) => {
    return await LabelProofsApi.addReviewer(companyId, proofId, taskId, {
      reviewerId
    })
  }
)

export const removeReviewer = createAsyncThunk(
  'labelproof/review/removeReviewer',
  async ({ companyId, proofId, taskId, reviewerId }: RemoveReviewerRequest) => {
    return await LabelProofsApi.removeReviewer(
      companyId,
      proofId,
      taskId,
      reviewerId
    ).then(() => {
      return { taskId, reviewerId }
    })
  }
)

const reviewSlice = createSlice({
  name: 'reviewSlice',
  initialState,
  reducers: {
    resetReviewState: () => initialState
  },
  extraReducers(builder) {
    // Get Tasks.
    builder.addCase(getTasks.pending, (state) => {
      state.loadingTasks = true
    })
    builder.addCase(
      getTasks.fulfilled,
      (state, action: PayloadAction<TaskApiResponse[]>) => {
        state.tasks = action.payload
        state.loadingTasks = false
      }
    )
    builder.addCase(getTasks.rejected, (state) => {
      state.loadingTasks = false
    })

    // Create Task.
    builder.addCase(createTask.pending, (state) => {
      state.addingTask = true
    })
    builder.addCase(
      createTask.fulfilled,
      (state, action: PayloadAction<TaskApiResponse>) => {
        state.tasks.push(action.payload)
        state.addingTask = false
      }
    )
    builder.addCase(createTask.rejected, (state) => {
      state.addingTask = false
    })

    // Update Task.
    builder.addCase(updateTask.pending, (state) => {
      state.updatingTask = true
    })
    builder.addCase(
      updateTask.fulfilled,
      (state, action: PayloadAction<TaskApiResponse>) => {
        const updatedTask = action.payload
        const taskIndex = state.tasks.findIndex(
          (task) => task.id === updatedTask.id
        )
        state.tasks[taskIndex] = updatedTask
        state.updatingTask = false
      }
    )
    builder.addCase(updateTask.rejected, (state) => {
      state.updatingTask = false
    })

    // Delete Task.
    builder.addCase(deleteTask.pending, (state) => {
      state.updatingTask = true
    })
    builder.addCase(
      deleteTask.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.tasks = state.tasks.filter((task) => task.id !== action.payload)
        state.updatingTask = false
      }
    )
    builder.addCase(deleteTask.rejected, (state) => {
      state.updatingTask = false
    })

    // Review Task.
    builder.addCase(reviewTask.pending, (state) => {
      state.updatingTask = true
    })
    builder.addCase(
      reviewTask.fulfilled,
      (state, action: PayloadAction<AddReviewerApiResponse>) => {
        const updatedTaskId = action.payload.taskId
        const reviewerId = action.payload.reviewer.id
        // Find the task.
        const taskIndex = state.tasks.findIndex(
          (task) => task.id === updatedTaskId
        )
        // Find the reviewer in the task and update the approved status.
        const reviewerIndex = state.tasks[taskIndex].reviewers.findIndex(
          (reviewer) => reviewer.reviewer.id === reviewerId
        )
        // If the reviwewer is found, update the approved status.
        if (reviewerIndex !== -1) {
          state.tasks[taskIndex].reviewers[reviewerIndex].approved =
            action.payload.approved
          // If it's unapproved, remove the approvedAt.
          state.tasks[taskIndex].reviewers[reviewerIndex].approvedAt = !action
            .payload.approved
            ? undefined
            : action.payload.approvedAt
        } else {
          // If the reviewer is not found, add the reviewer to the task.
          state.tasks[taskIndex].reviewers.push({
            reviewer: action.payload.reviewer,
            approved: action.payload.approved,
            taskId: updatedTaskId,
            assignedAt: action.payload.assignedAt,
            approvedAt: action.payload.approvedAt,
            permissions: action.payload.permissions
          })
        }
        state.updatingTask = false
      }
    )
    builder.addCase(reviewTask.rejected, (state) => {
      state.updatingTask = false
    })

    // Add Reviewer.
    builder.addCase(addReviewer.pending, (state) => {
      state.updatingTask = true
    })
    builder.addCase(
      addReviewer.fulfilled,
      (state, action: PayloadAction<AddReviewerApiResponse>) => {
        const updatedTaskId = action.payload.taskId
        // Find the task.
        const taskIndex = state.tasks.findIndex(
          (task) => task.id === updatedTaskId
        )
        // Add the reviewer to the task.
        state.tasks[taskIndex].reviewers.push({
          reviewer: action.payload.reviewer,
          approved: false,
          taskId: updatedTaskId,
          assignedAt: action.payload.assignedAt,
          approvedAt: action.payload.approvedAt,
          permissions: action.payload.permissions
        })
        state.updatingTask = false
      }
    )
    builder.addCase(addReviewer.rejected, (state) => {
      state.updatingTask = false
    })

    // Remove Reviewer.
    builder.addCase(removeReviewer.pending, (state) => {
      state.updatingTask = true
    })
    builder.addCase(
      removeReviewer.fulfilled,
      (
        state,
        action: PayloadAction<{ taskId: string; reviewerId: string }>
      ) => {
        const updatedTaskId = action.payload.taskId
        const reviewerId = action.payload.reviewerId
        // Find the task.
        const taskIndex = state.tasks.findIndex(
          (task) => task.id === updatedTaskId
        )
        // Remove the reviewer from the task.
        state.tasks[taskIndex].reviewers = state.tasks[
          taskIndex
        ].reviewers.filter((reviewer) => reviewer.reviewer.id !== reviewerId)
        state.updatingTask = false
      }
    )
    builder.addCase(removeReviewer.rejected, (state) => {
      state.updatingTask = false
    })

    // Get Reviewer Options.
    builder.addCase(getReviewerOptions.pending, (state) => {
      state.loadingReviewerOptions = true
    })
    builder.addCase(
      getReviewerOptions.fulfilled,
      (state, action: PayloadAction<BasicUser[]>) => {
        state.reviewerOptions = action.payload
        state.loadingReviewerOptions = false
      }
    )
    builder.addCase(getReviewerOptions.rejected, (state) => {
      state.loadingReviewerOptions = false
    })
  }
})

export const { resetReviewState } = reviewSlice.actions
export default reviewSlice.reducer
