import { useLoadNextPage } from 'common/hooks'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import React from 'react'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  createProof,
  getCompanyPermissions,
  getFormulaOptions
} from 'state/labelproof/proofs/ProofsSice'
import { NewProofButton } from './NewProofButton'

export const NewProofContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const companyPermissions = useAppSelector(
    (state) => state.proofs.companyPermissions
  )
  const [searchValue, setSearchValue] = React.useState<string>('')
  const { showInfoModal } = React.useContext(ModalContext)
  const { showError } = React.useContext(SnackbarContext)

  const { items, page, pages } = useAppSelector(
    (state) => state.proofs.formulaOptions
  )

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const createNewProof = (id: string) => {
    void dispatch(
      createProof({
        companyId: companyId,
        formulaId: id
      })
    ).then((action) => {
      if (createProof.fulfilled.match(action)) {
        navigate(`/labelproofs/${action.payload.id}`)
      } else if (createProof.rejected.match(action)) {
        showInfoModal({
          title: 'Limit Reached',
          message: action.error.message,
          yesText: 'Ok'
        })
      }
    })
  }

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      void dispatch(
        getFormulaOptions({
          companyId: companyId,
          page: page,
          fuzzyName: searchValue
        })
      ).catch(() => {
        showError('Failed to load formulas')
      })
    },
    [companyId, searchValue]
  )

  React.useEffect(() => {
    if (!companyPermissions) {
      void dispatch(getCompanyPermissions(companyId))
    }
    void dispatch(
      getFormulaOptions({
        companyId: companyId,
        page: 1,
        fuzzyName: searchValue
      })
    ).catch(() => {
      showError('Failed to load formulas')
    })
  }, [companyId, searchValue])

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  const data = React.useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      name: item.name,
      friendlyId: item.friendlyId
    }))
  }, [items])

  return (
    <NewProofButton
      data={data}
      search={{
        value: searchValue,
        onChange: handleSearchChange
      }}
      onRowClick={createNewProof}
      allPagesLoaded={allPagesLoaded}
      onLoadNextPage={nextPageCallback}
    />
  )
}
