import {
  BoxRow,
  EntrTableContainer,
  PageEntrTableInnerContainer
} from 'components/common'
import { EntrCardProps } from 'components/EntrGrid/EntrCard'
import { EntrGrid } from 'components/EntrGrid/EntrGrid'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import React from 'react'
import {
  ProofCardContent,
  ProofCardProps
} from './components/Grid/ProofCardContent'
import { Typography } from '@mui/material'

enum Messages {
  NO_DATA = "It's empty here. Create a new proof!",
  NO_PERMISSION = 'You do not have permission to view proofs.'
}

interface LabelProofProps {
  filterAndSearch: React.ReactNode
  proofs: Omit<ProofCardProps, 'onClick'>[]
  newProofAction: React.ReactNode
  loading?: boolean
  allPagesLoaded: boolean
  canListProofs: boolean
  onProofClick: (id: string) => void
  onLoadNextPage: () => void
}

export const LabelProofs: React.FC<LabelProofProps> = ({
  filterAndSearch,
  proofs,
  newProofAction,
  loading,
  allPagesLoaded,
  canListProofs,
  onProofClick,
  onLoadNextPage
}) => {
  const proofCards: EntrCardProps[] = proofs.map((proof) => ({
    id: proof.id,
    children: <ProofCardContent {...proof} />,
    onClick: () => onProofClick(proof.id)
  }))

  return (
    <>
      <PageEntrTableInnerContainer>
        <EntrTableContainer>
          <TitleActionsBar title={'Label Proofs'} action={newProofAction} />
          {canListProofs ? (
            <>
              {filterAndSearch}
              <EntrGrid
                cards={proofCards}
                onCardClick={onProofClick}
                noDataMessage={Messages.NO_DATA}
                loading={loading}
                allPagesLoaded={allPagesLoaded}
                onLoadNextPage={onLoadNextPage}
              />
            </>
          ) : (
            <BoxRow sx={{ justifyContent: 'center' }}>
              <Typography>{Messages.NO_PERMISSION}</Typography>
            </BoxRow>
          )}
        </EntrTableContainer>
      </PageEntrTableInnerContainer>
    </>
  )
}
