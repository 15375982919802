import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { ReviewersProps } from './ReviewItem/components/Reviewers/Reviewers'
import { TaskProps } from './ReviewItem/components/Task/Task'
import { ReviewItem } from './ReviewItem/ReviewItem'
import { ReviewItemCreator } from './ReviewItemAdder'
import { Skeleton, Typography } from '@mui/material'
import { OwnerProps } from './ReviewItem/components/Owner/Owner'

export interface ReviewItem
  extends Omit<TaskProps, 'onCheckChange' | 'onNameChange' | 'onDeleteClick'>,
    Omit<ReviewersProps, 'onRemove' | 'onAdd'> {
  owner: OwnerProps
}

export interface ReviewListProps {
  reviewItems: ReviewItem[]
  loading?: boolean
  addItemDisabled: boolean
  listItemsDisabled: boolean
  onAddItem: (name: string) => Promise<void>
  onDeleteItem: (id: string) => void
  onChangeCheckItem: (id: string, checked: boolean) => void
  onChangeItemName: (id: string, name: string) => void
  onAssignReviewer: (id: string, reviewerId: string) => void
  onUnassignReviewer: (id: string, reviewerId: string) => void
}

const ReviewItemsSkeleton: React.FC<{ total: number }> = React.memo(
  ({ total }) => {
    return (
      <>
        {Array.from({ length: total }).map((_, index) => (
          <Skeleton key={`review-item-skeleton-${index}`} height={'42px'} />
        ))}
      </>
    )
  }
)

export const ReviewList: React.FC<ReviewListProps> = ({
  reviewItems,
  addItemDisabled,
  listItemsDisabled,
  onAddItem,
  onDeleteItem,
  onChangeCheckItem,
  onChangeItemName,
  onAssignReviewer,
  onUnassignReviewer,
  loading = false
}) => {
  return (
    <BoxColumnBasic height={'100%'}>
      {loading ? (
        <ReviewItemsSkeleton total={5} />
      ) : (
        <>
          <ReviewItemCreator onAdd={onAddItem} disabled={addItemDisabled} />
          {listItemsDisabled ? (
            <Typography
              sx={{
                color: 'rgba(35, 60, 88, 0.43)'
              }}
            >
              Displaying tasks is disabled
            </Typography>
          ) : (
            reviewItems.map((item) => (
              <ReviewItem
                key={`review-item-${item.id}`}
                taskProps={{
                  id: item.id,
                  name: item.name,
                  checked: item.checked,
                  completed: item.completed,
                  checkable: item.checkable,
                  editable: item.editable,
                  deletable: item.deletable,
                  onCheckChange: (checked) =>
                    onChangeCheckItem(item.id, checked),
                  onNameChange: (name) => onChangeItemName(item.id, name),
                  onDeleteClick: () => onDeleteItem(item.id)
                }}
                reviewersProps={{
                  onAdd: (reviewerId) => onAssignReviewer(item.id, reviewerId),
                  onRemove: (reviewerId) =>
                    onUnassignReviewer(item.id, reviewerId),
                  reviewerOptions: item.reviewerOptions,
                  addReviewerDisabled: item.addReviewerDisabled,
                  reviewers: item.reviewers
                }}
                ownerProps={item.owner}
              />
            ))
          )}
        </>
      )}
    </BoxColumnBasic>
  )
}
