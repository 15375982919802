import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormulasPaginated, FormulaStatus } from 'models/Formula'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { fromFormulaStatus } from 'services/apis/formula/FormulaApiMapper'
import {
  CompanyScopePermissionsApiResponse,
  LabelProofsApiPaginatedResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { GetFormulasRequest } from 'state/formulas/FormulasRequest'
import { CreateProofRequest, GetProofsRequest } from './ProofsRequest'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import { pluralize } from 'common/utils'

interface ProofsState {
  formulaOptions: FormulasPaginated
  proofs: LabelProofsApiPaginatedResponse
  loadingFormulaOptions: boolean
  loadingProofs: boolean
  companyPermissions?: CompanyScopePermissionsApiResponse
}

const initialState: ProofsState = {
  formulaOptions: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 1
  },
  proofs: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 1
  },
  loadingProofs: false,
  loadingFormulaOptions: false
}

export const getFormulaOptions = createAsyncThunk(
  'proofs/formulaOptions/get',
  async ({ companyId, page = 1, size = 50, fuzzyName }: GetFormulasRequest) => {
    // The only allowed statuses are anything except private draft.
    const allowedStatuses = [
      FormulaStatus.IN_REVIEW,
      FormulaStatus.APPROVED,
      FormulaStatus.IN_MARKET
    ]
    return await FormulaApi.getFormulas(companyId, {
      page: page,
      size: size,
      simpleFormulaFuzzyName: fuzzyName,
      status: allowedStatuses.map((s) => fromFormulaStatus(s))
    })
  }
)

export const getCompanyPermissions = createAsyncThunk(
  'proofs/companyPermissions/get',
  async (companyId: string) => {
    return await LabelProofsApi.getCompanyPermissions(companyId)
  }
)

export const getProofs = createAsyncThunk(
  'proofs/get',
  async ({
    companyId,
    fuzzyName,
    createdBy,
    status,
    page = 1,
    size = 50
  }: GetProofsRequest) => {
    return await LabelProofsApi.getLabelProofs(companyId, {
      page: page,
      size: size,
      createdBy: createdBy,
      labelProofFuzzyName: fuzzyName,
      status: status
    })
  }
)

export const createProof = createAsyncThunk(
  'proofs/create',
  async ({ companyId, formulaId }: CreateProofRequest) => {
    return await LabelProofsApi.createLabelProof(companyId, {
      formulaId: formulaId,
      name: 'Untitled Proof'
    }).catch((error) => {
      const response = error.response
      if (response.status === 403) {
        const details = response.data.details
        const message = `Label proofing is currently limited to ${
          details.limit
        } ${pluralize(
          details.limit,
          'proof'
        )} per account. To unlock unlimited proofs, please contact your account manager or email us at ${
          details.contactEmail
        }.`
        return Promise.reject(message)
      }
      return Promise.reject('Failed to create proof')
    })
  }
)

const proofsSlice = createSlice({
  name: 'proofsSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFormulaOptions.pending, (state) => {
      state.loadingFormulaOptions = true
    })
    builder.addCase(
      getFormulaOptions.fulfilled,
      (state, action: PayloadAction<FormulasPaginated>) => {
        if (action.payload.page === 1) {
          state.formulaOptions = {
            ...action.payload
          }
        } else {
          state.formulaOptions = {
            ...action.payload,
            items: action.payload.items.reduce((acc, currentItem) => {
              // Check if the item already exists in the current state
              const existingItem = state.formulaOptions.items.find(
                (item) => item.id === currentItem.id
              )

              // If the item already exists, replace it; otherwise, add it to the accumulator
              if (existingItem) {
                return acc.map((item) =>
                  item.id === currentItem.id ? currentItem : item
                )
              } else {
                return [...acc, currentItem]
              }
            }, state.formulaOptions.items)
          }
        }
        state.loadingFormulaOptions = false
      }
    )
    builder.addCase(getFormulaOptions.rejected, (state) => {
      state.formulaOptions = initialState.formulaOptions
      state.loadingFormulaOptions = false
    })

    builder.addCase(getCompanyPermissions.fulfilled, (state, action) => {
      state.companyPermissions = action.payload
    })

    builder.addCase(getProofs.pending, (state) => {
      state.loadingProofs = true
    })
    builder.addCase(
      getProofs.fulfilled,
      (state, action: PayloadAction<LabelProofsApiPaginatedResponse>) => {
        state.proofs = action.payload
        state.loadingProofs = false
      }
    )
    builder.addCase(getProofs.rejected, (state) => {
      state.proofs = initialState.proofs
      state.loadingProofs = false
    })

    builder.addCase(createProof.pending, (state) => {
      state.loadingProofs = true
    })
    builder.addCase(createProof.fulfilled, (state, action) => {
      state.proofs.items.push(action.payload)
    })
    builder.addCase(createProof.rejected, (state) => {
      state.loadingProofs = false
    })
  }
})

export default proofsSlice.reducer
