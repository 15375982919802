import { Box, IconButton, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import React from 'react'
import {
  CreateProofCommentRequestData,
  UpdateProofCommentRequestData
} from 'state/labelproof/proofer/ProoferRequest'
import { ReactComponent as PinIconGrey } from 'assets/PinIconGrey.svg'
import { ReactComponent as PinIcon } from 'assets/PinIcon.svg'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { CommentText } from 'components/Comments/CommentText'
import {
  BlueHighlight,
  mentionParser,
  pinParser
} from 'components/Comments/TextParsers'
import { setHighlightedPinId } from 'state/labelproof/proofer/ProoferSlice'
import {
  resetStagedComment,
  setEditingComment,
  setStagedComment
} from 'state/labelproof/proofer/ProoferActivitiesSlice'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ProofCommentInput } from './ProofCommentInput'
import ClearIcon from '@mui/icons-material/Clear'
import {
  mapStagedCommentToCreateProofCommentRequestData,
  mapStagedCommentToUpdateProofCommentRequestData
} from '../../ActivitiesMappers'

export interface ProofCommentFormProps {
  onSubmit: (comment: CreateProofCommentRequestData) => Promise<void>
  onUpdate: (
    commentId: string,
    comment: UpdateProofCommentRequestData
  ) => Promise<void>
  onRemoveReply: () => void
  togglePinMode: () => void
  pinMode: boolean
  disabled?: boolean
  notEnoughPermission?: boolean
}

export const ProofCommentForm: React.FC<ProofCommentFormProps> = ({
  onSubmit,
  onUpdate,
  onRemoveReply,
  togglePinMode,
  pinMode,
  disabled,
  notEnoughPermission
}) => {
  const dispatch = useAppDispatch()
  const stagedComment = useAppSelector(
    (state) => state.prooferActivities.stagedComment
  )
  const editingComment = useAppSelector(
    (state) => state.prooferActivities.editingComment
  )

  const removeStagedCommentPin = React.useCallback(() => {
    dispatch(
      setStagedComment({
        ...stagedComment,
        pin: undefined
      })
    )
  }, [dispatch, stagedComment])

  const submitDisabled = React.useMemo(() => {
    return !stagedComment.comment.trim() || disabled
  }, [stagedComment, disabled])

  const handleSubmit = () => {
    if (submitDisabled) {
      return
    }
    onSubmit(mapStagedCommentToCreateProofCommentRequestData(stagedComment))
    dispatch(resetStagedComment())
  }

  const handleUpdate = () => {
    if (submitDisabled) {
      return
    }
    if (!stagedComment.id) {
      return
    }
    onUpdate(
      stagedComment.id,
      mapStagedCommentToUpdateProofCommentRequestData(stagedComment)
    )
    handleDiscard()
  }

  const handleDiscard = () => {
    dispatch(resetStagedComment())
    dispatch(setEditingComment(false))
  }

  return (
    <BoxColumnBasic>
      {stagedComment?.replyTo && (
        <>
          <BoxColumnBasic padding="0px 0px 15px 15px" gap={0.5}>
            <BoxRowBasic
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography
                sx={{ fontStyle: 'italic', color: 'gray', fontSize: '13px' }}
              >
                Reply to {stagedComment?.replyTo.createdByName || ''}:
              </Typography>
              <IconButton color="inherit" onClick={onRemoveReply}>
                <CloseRoundedIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#233C5860'
                  }}
                />
              </IconButton>
            </BoxRowBasic>
            <CommentText
              text={stagedComment?.replyTo.comment}
              parsers={[
                mentionParser(stagedComment?.replyTo.mentionsMap),
                pinParser(stagedComment?.replyTo.pinMap, () =>
                  dispatch(setHighlightedPinId(stagedComment.replyTo?.pinId))
                )
              ]}
              variant="staged-replied"
            />
          </BoxColumnBasic>
        </>
      )}
      <BoxColumnBasic>
        <BoxColumnBasic sx={{ gap: '5px' }}>
          <ProofCommentInput
            disabled={disabled}
            notEnoughPermission={notEnoughPermission}
          />
          <BoxRowBasic
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px 15px'
            }}
          >
            <BoxRowBasic
              sx={{
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {stagedComment.pin && (
                <Box sx={{ position: 'relative' }}>
                  <BlueHighlight
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      !pinMode &&
                      dispatch(setHighlightedPinId(stagedComment.pin?.id))
                    }
                  >
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        verticalAlign: 'bottom'
                      }}
                    >
                      <PinIcon style={{ marginRight: '4px' }} /> Pin
                    </span>
                  </BlueHighlight>
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '-8px',
                      top: '-10px',
                      zIndex: '1'
                    }}
                  >
                    <Button
                      sx={{ width: '10px', height: '10px' }}
                      variant="circle"
                      color="tertiary"
                      onClick={removeStagedCommentPin}
                      disabled={disabled}
                    >
                      <ClearIcon sx={{ width: '10px', height: '10px' }} />
                    </Button>
                  </Box>
                </Box>
              )}
            </BoxRowBasic>
            <BoxRowBasic
              sx={{
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '25px'
              }}
            >
              <IconButton
                disabled={disabled}
                color="inherit"
                aria-label="open sidebar"
                onClick={togglePinMode}
                sx={{
                  position: 'relative',
                  width: '30px',
                  height: '30px'
                }}
              >
                {pinMode ? <PinIcon /> : <PinIconGrey />}
              </IconButton>
              {editingComment && (
                <Button color="secondary" onClick={(e) => handleDiscard()}>
                  Discard
                </Button>
              )}
              <Button
                color="secondary"
                disabled={submitDisabled}
                onClick={(e) =>
                  editingComment ? handleUpdate() : handleSubmit()
                }
              >
                {editingComment ? 'Save' : 'Send'}
              </Button>
            </BoxRowBasic>
          </BoxRowBasic>
        </BoxColumnBasic>
      </BoxColumnBasic>
    </BoxColumnBasic>
  )
}
