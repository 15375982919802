import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import React from 'react'
import { TabContent } from 'pages/Formulator/components/FormulatorPanel/TabContent'
import {
  ProofCommentForm,
  ProofCommentFormProps
} from './components/ProofCommentForm/ProofCommentForm'
import { InView } from 'react-intersection-observer'
import { CircularProgress, Skeleton } from '@mui/material'
import { ProofLog, ProofLogProps } from './components/ProofLog/ProofLog'
import {
  ProofComment,
  ProofCommentProps,
  isProofCommentProps
} from './components/ProofComment/ProofComment'

const ActivityItemSkeleton: React.FC = () => (
  <BoxRowBasic gap="1rem">
    <BoxColumnBasic>
      <Skeleton variant="circular" height={40} width={40} />
    </BoxColumnBasic>
    <BoxColumnBasic gap={1} sx={{ flexGrow: 1 }}>
      <BoxRowBasic gap={1}>
        <Skeleton variant="rectangular" height={10} sx={{ flexGrow: 0.25 }} />
      </BoxRowBasic>
      <Skeleton variant="rectangular" height={30} sx={{ flexGrow: 1 }} />
    </BoxColumnBasic>
  </BoxRowBasic>
)

const ActivitiesSkeleton: React.FC<{ numberOfItems?: number }> = ({
  numberOfItems = 5
}) => (
  <>
    {Array.from({ length: numberOfItems }, (_, index) => (
      <ActivityItemSkeleton key={index} />
    ))}
  </>
)

export interface ActivitiesProps {
  allPagesLoaded: boolean
  nextPageCallback: () => void
  proofCommentFormProps: ProofCommentFormProps
  activitiesProps?: (ProofCommentProps | ProofLogProps)[]
  loading?: boolean
  page?: number
}

export const Activities: React.FC<ActivitiesProps> = ({
  allPagesLoaded,
  nextPageCallback,
  proofCommentFormProps,
  activitiesProps = [],
  loading = false,
  page = 1
}) => {
  const renderActivityItem = (
    item: ProofCommentProps | ProofLogProps,
    index: number
  ) => {
    if (isProofCommentProps(item)) {
      return <ProofComment key={`proof-comment-${index}`} {...item} />
    } else {
      return <ProofLog key={`proof-log-${index}`} {...item} />
    }
  }

  return (
    <TabContent
      footerElement={<ProofCommentForm {...proofCommentFormProps} />}
      maintainScrollPosition={true}
    >
      {!allPagesLoaded && activitiesProps.length > 0 && (
        <InView
          as="div"
          onChange={(inView: boolean) => {
            if (inView && activitiesProps.length > 0 && !loading) {
              nextPageCallback()
            }
          }}
          trackVisibility={true}
          delay={300}
        >
          <BoxRowBasic sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </BoxRowBasic>
        </InView>
      )}
      <BoxColumnBasic gap="1rem" height="100%">
        {loading ? (
          <ActivitiesSkeleton />
        ) : (
          activitiesProps.map(renderActivityItem)
        )}
      </BoxColumnBasic>
    </TabContent>
  )
}
