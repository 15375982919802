import { PATHS } from 'common/constants'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { deleteProof, updateProof } from 'state/labelproof/proofer/ProoferSlice'
import { TopBar } from './TopBar'

export const TopBarContainer: React.FC = () => {
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showSuccess, showError } = React.useContext(SnackbarContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaName =
    useAppSelector((state) => state.proofer.proof?.formula.name) || ''
  const proofName = useAppSelector((state) => state.proofer.proof?.name) || ''
  const proofId = useAppSelector((state) => state.proofer.proof?.id) || ''
  const loading = useAppSelector((state) => state.proofer.loadingProof)
  const saving = useAppSelector(
    (state) =>
      state.proofer.savingProof ||
      state.review.updatingTask ||
      state.review.addingTask
  )
  const permissions = useAppSelector(
    (state) => state.proofer.proof?.permissions
  )

  const { labelProoferAnalytics } = React.useContext(AnalyticsContext)

  const handleDelete = () => {
    showConfirmationModal({
      title: 'Delete Proof',
      danger: true,
      message: (
        <>
          Are you sure you want to permanently delete the proof{' '}
          <b>{proofName}</b>?
        </>
      ),
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: () => {
        labelProoferAnalytics.deletedProof(proofId)
        void dispatch(deleteProof({ companyId: currentCompany.id, proofId }))
          .then(() => {
            showSuccess(`${proofName} has been deleted.`)
            navigate(PATHS.LABEL_PROOFS)
          })
          .catch(() => {
            showError(`Failed to delete ${proofName}`)
          })
      }
    })
  }

  const renamable = React.useMemo(
    () => !saving && !loading && !!permissions?.editLabelProof,
    [saving, loading, permissions]
  )
  const deletable = React.useMemo(
    () =>
      !saving &&
      !loading &&
      (!!permissions?.deleteLabelProof || !!permissions?.archiveLabelProof),
    [saving, loading, permissions]
  )

  const handleProofNameChanged = (name: string) => {
    if (!renamable) {
      return
    }

    labelProoferAnalytics.changedProofName(proofId)
    if (name != proofName) {
      void dispatch(
        updateProof({
          companyId: currentCompany.id,
          proofId: proofId,
          name: name
        })
      )
    }
  }

  return (
    <TopBar
      loading={loading}
      saving={saving}
      onBackButtonClick={() => navigate(PATHS.LABEL_PROOFS)}
      onDeleteClick={handleDelete}
      formulaName={formulaName}
      proofName={proofName}
      renamable={renamable}
      deletable={deletable}
      onProofNameChanged={handleProofNameChanged}
    />
  )
}
